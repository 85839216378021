<template>
  <div>
    <eden-page-header :title="'Combo Check'" />
    <combo-check-items :allow-marking="true" />
  </div>
</template>

<script>
import ComboCheckItems from "@/components/Stewards/ComboCheck/ComboCheckItems";
export default {
  name: "ComboCheck",
  components: { ComboCheckItems },
  data() {
    return {};
  },
};
</script>
